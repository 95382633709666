<template>
  <div>
    <div v-show="!isLoaded">
      <spin-loader />
    </div>
    <div v-show="isLoaded">
      <b-row class="justify-content-center">
        <b-col
          cols="12"
          md="12"
          lg="11"
        >
          <div v-if="!isCreateMode">
            <b-row>
              <b-col
                class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
              >
                <b-dropdown
                  id="actions"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  right
                  class="dropdown-icon-wrapper"
                >
                  <template #button-content>
                    <feather-icon
                      icon="SettingsIcon"
                      size="16"
                      class="align-middle"
                    />
                  </template>
                  <b-dropdown-item
                    @click="showConfirmOrCancelDelete()"
                  >
                    <svg-icon
                      type="mdi"
                      :path="mdiTrashCanOutline"
                    />
                    <span class="align-middle ml-50">Remover</span>
                  </b-dropdown-item>
                </b-dropdown>
              </b-col>
            </b-row>
          </div>
          <validation-observer
            v-slot="{ handleSubmit, invalid }"
            ref="refFormObserver"
          >
            <!-- Form: Info Form -->
            <b-form
              class="mt-1"
              @submit.prevent="handleSubmit(onSubmit)"
            >

              <b-card
                class="mb-2"
                no-body
              >
                <b-card-title
                  class="d-flex justify-content-between mt-1 mx-2 mb-0"
                >
                  <div
                    class="d-flex align-items-center"
                  >
                    <span class="d-inline cardTitle">Informações</span>
                  </div>
                </b-card-title>
                <hr class="mt-1">
                <b-card-body
                  class="pt-1"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <!-- Company Name -->
                      <validation-provider
                        #default="validationContext"
                        name="company_name"
                        rules="required"
                      >
                        <b-form-group
                          label="Nome da Empresa"
                          label-for="company_name"
                        >
                          <b-form-input
                            id="company_name"
                            v-model="account.name"
                            :state="getValidationState(validationContext)"
                            placeholder="Nome da Empresa"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="cnpj"
                        :rules="{
                          required: true,
                          min:14,
                          regex:/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/
                        }"
                      >
                        <b-form-group
                          label="CNPJ"
                          label-for="cnpj"
                        >
                          <cleave
                            id="cnpj"
                            v-model="account.cnpj"
                            :class="errors.length > 0 ? 'form-control is-invalid' : 'form-control'"
                            :raw="false"
                            :options="masks.cnpjDelimiter"
                            placeholder="00.000.000/0000-00"
                            :readonly="false"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <profile-select
                        id="accountProfile"
                        v-model="account.profile_set"
                        :readonly="false"
                        :required-field="true"
                        @ready="syncLoad.ready('accountProfile')"
                      />
                    </b-col>
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <!-- Account Situation -->
                      <situation-select
                        id="accountSituation"
                        v-model="account.situation"
                        label="Situação da Conta"
                        placeholder="Selecione a Situação da Conta"
                        :readonly="false"
                        :required-field="true"
                        @ready="syncLoad.ready('situationAccount')"
                      />
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <b-card
                class="mb-2"
                no-body
              >
                <b-card-title
                  class="d-flex justify-content-between mt-1 mx-2 mb-0"
                >
                  <div
                    class="d-flex align-items-center"
                  >
                    <span class="d-inline cardTitle">Responsável</span>
                  </div>
                </b-card-title>
                <hr class="mt-1">
                <b-card-body
                  class="pt-1"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="owner_name"
                        rules="required"
                      >
                        <b-form-group
                          label="Nome do Responsável"
                          label-for="owner_name"
                        >
                          <b-form-input
                            id="owner_name"
                            v-model="account.owner_name"
                            :state="getValidationState(validationContext)"
                            placeholder="Nome do Responsável"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="email"
                        :rules="{
                          required: false,
                          email: true,
                        }"
                      >
                        <b-form-group
                          label="E-mail"
                          label-for="owner_email"
                        >
                          <b-form-input
                            id="owner_email"
                            v-model="account.owner_email"
                            :state="getValidationState(validationContext)"
                            :readonly="false"
                            trim
                            :placeholder="'exemplo@syslic.com'"
                          />

                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      md="4"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="phone_number"
                      >
                        <b-form-group
                          label="Telefone"
                          label-for="owner_phone_number"
                        >
                          <b-form-input
                            id="owner_phone_number"
                            v-model="account.owner_phone_number"
                            v-mask="getMask('owner_phone_number')"
                            :state="getValidationState(validationContext)"
                            :readonly="false"
                            :placeholder="'(99) 99999-9999'"
                          />
                          <b-form-invalid-feedback>
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <b-card
                class="mb-2"
                no-body
              >
                <b-card-title
                  class="d-flex justify-content-between mt-1 mx-2 mb-0"
                >
                  <div
                    class="d-flex align-items-center"
                  >
                    <span class="d-inline cardTitle">Endereço</span>
                  </div>
                </b-card-title>
                <hr class="mt-1 mb-50">
                <b-card-body
                  class="p-0"
                >
                  <address-edit-fields
                    id="address-fields-component"
                    :address="account"
                    :is-view-mode="false"
                    :required-fields="[]"
                    :enabled-fields="enabledFieldsAddress"
                    :zip-code-field="'zip_code'"
                    :address-field="'address'"
                    :number-field="'number'"
                    :complement-field="'complement'"
                    :neighborhood-field="'neighborhood'"
                    :country-field="'country'"
                    :state-field="'state'"
                    :city-field="'city'"
                    @ready="syncLoad.ready('address')"
                  />
                </b-card-body>
              </b-card>
              <b-row
                class="mt-2"
              >
                <b-col
                  class="d-flex justify-content-end buttonsSaveCancel"
                >
                  <b-button
                    id="accountSaveButton"
                    type="submit"
                    variant="primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :disable="invalid"
                  >
                    Salvar
                  </b-button>
                  <b-button
                    id="accountCancelButton"
                    variant="outline-primary"
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                    :to="{ name: 'administrator-accounts' }"
                  >
                    Voltar
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>

      <b-modal
        id="idModalUpdateAccount"
        ref="modalUpdateAccount"
        no-close-on-backdrop
        no-close-on-esc
        hide-header
        hide-footer
        centered
      >
        <div class="pt-5 pb-4">
          <div class="d-flex justify-content-center">
            <b-spinner
              id="spinnerUpdate"
              variant="primary"
              type="grow"
            />
          </div>
          <h4
            class="d-flex justify-content-center mt-2 pl-50"
          >
            Atualizando...
          </h4>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import log from 'loglevel'
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BModal,
  VBTooltip,
} from 'bootstrap-vue'

import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'

import {
  mdiTrashCanOutline,
} from '@mdi/js'

import SvgIcon from '@jamescoyle/vue-icon'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import Cleave from 'vue-cleave-component'
import syslic from '@/syslic'
import router from '@/router'
import SpinLoader from '@/components/spinloader/SpinLoader.vue'
import SyncLoad from '@/components/utils/syncLoad'
import ProfileSelect from '@/components/administrator/ProfileSelect.vue'
import SituationSelect from '@/components/administrator/SituationSelect.vue'
import AddressEditFields from '@/components/address/AddressEditFields.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,
    BSpinner,
    BModal,
    SvgIcon,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    SpinLoader,
    ProfileSelect,
    SituationSelect,
    AddressEditFields,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    let isCreateMode = false

    const originalProfile = null
    const account = {
      address: '',
      city: '',
      city_set: null,
      cnpj: '',
      complement: '',
      country: '',
      country_set: null,
      id: '',
      name: '',
      neighborhood: '',
      number: '',
      owner_email: '',
      owner_name: '',
      owner_phone_number: '',
      profile: '',
      profile_set: null,
      situation: 'active',
      state: '',
      state_set: null,
      zip_code: '',
    }

    if (router.currentRoute.path.match(/.*\/create/)) {
      isCreateMode = true
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation(this.account)

    const isLoaded = false

    const syncLoad = new SyncLoad()
    syncLoad.addTrigger('situationAccount')
    syncLoad.addTrigger('accountProfile')
    syncLoad.addTrigger('account')
    syncLoad.addTrigger('address')

    syncLoad.onReady(() => {
      this.isLoaded = true
    })

    const masks = {
      cnpjDelimiter: {
        delimiters: ['.', '.', '/', '-'],
        blocks: [2, 3, 3, 4, 2],
        uppercase: true,
      },
    }

    const enabledFieldsAddress = [
      'address',
      'neighborhood',
      'number',
      'complement',
      'city',
      'state',
      'country',
      'zip_code',
    ]

    return {
      required,
      isCreateMode,
      account,
      refFormObserver,
      getValidationState,
      accountId: null,
      isLoaded,
      syncLoad,
      masks,
      originalProfile,
      enabledFieldsAddress,
    }
  },
  watch: {
    'account.profile_set': function updateAccountModel(obj) {
      if (obj) {
        this.account.profile = obj.id
      } else {
        this.account.profile = ''
      }
    },
  },
  created() {
    this.accountId = router.currentRoute.params.id
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (this.accountId !== undefined) {
        syslic
          .administrator
          .account
          .fetchAccount(this.accountId)
          .then(response => {
            this.account = response.data
            this.originalProfile = this.account.profile
            this.syncLoad.ready('account')
          })
          .catch(error => {
            if (error.status === 404) {
              router.push({ name: 'error-404' })
            }
            this.syncLoad.ready('account')
          })
      } else {
        this.syncLoad.ready('account')
      }
    },
    createAccount() {
      syslic
        .administrator
        .account
        .addAccount(this.account)
        .then(response => {
          this.accountId = response.data.id
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Conta adicionada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({
            name: 'administrator-account-edit',
            params: {
              id: this.accountId,
            },
          })
        })
        .catch(() => {
          this.text_error = 'Não foi possível adicionar a conta, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao adicionar uma nova conta.',
              text: this.text_error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    checkProfileChange() {
      if (this.account.profile !== this.originalProfile) {
        this.$bvModal
          .msgBoxConfirm('Atenção, a mudança de plano está sujeita a adição de novos dados ou módulos ao sistema, deseja confirmar?', {
            id: 'confirmation_box',
            title: 'Confirmar?',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'Sim',
            cancelTitle: 'Não',
            cancelVariant: 'outline-primary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.updateAccount()
            }
          })
      } else {
        this.updateAccount()
      }
    },
    updateAccount() {
      this.$refs.modalUpdateAccount.show()

      syslic
        .administrator
        .account
        .updateAccount(this.accountId, this.account)
        .then(response => {
          log.info('Update Account')
          this.account = response.data
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Conta atualizada com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.text_error = 'Não foi possível atualiazar a conta, por favor entre em contato com o administrador do sistema.'

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao atualiazar a conta.',
              text: this.text_error,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
        .finally(() => {
          this.$refs.modalUpdateAccount.hide()
        })
    },
    onSubmit() {
      if (this.isCreateMode) {
        this.createAccount()
      } else {
        this.checkProfileChange()
      }
    },
    deleteAccount(id) {
      syslic
        .administrator
        .account
        .deleteAccount(id)
        .then(() => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Conta removida com sucesso.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'administrator-accounts' })
        })
        .catch(error => {
          let textError = 'Não foi possível remover esta conta, por favor entre em contato com o administrador do sistema.'

          if ('data' in error) {
            if (error.data.includes('they are referenced through protected foreign keys')) {
              textError = 'Esta conta é referenciado, por este motivo não será possível exclui-la.'
            }
          }

          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Falha ao remover conta.',
              text: textError,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              timeout: false,
            },
          })
        })
    },
    showConfirmOrCancelDelete() {
      this.$bvModal
        .msgBoxConfirm('Você realmente quer remover essa conta?', {
          id: 'confirmation_box',
          title: 'Confirmar?',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteAccount(this.accountId)
          }
        })
    },
    getMask(field) {
      let mask = '(##) ####-####'
      if (this.account[field]) {
        if (this.account[field].length > 14) {
          mask = '(##) #####-####'
        }
      }

      return mask
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
      mdiTrashCanOutline,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/base/core/colors/palette-variables.scss";

.buttonsSaveCancel{
  .btn{
    min-width: 107px;
  }
}

.cardTitle {
  color: #6E6B7B;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Montserrat;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.isEven {
  background-color: #fafafc;
}

.moduleName {
  min-width: 18rem;
}

#spinnerUpdate {
  width: 5rem;
  height: 5rem;
}

#idModalUpdateAccount {
  #idModalUpdateAccount___BV_modal_content_{
    border-radius:20px !important;
  }
}
</style>
